import { useState, useEffect } from 'react'
import { Box, Button, Stack, Typography } from '@mui/material'
import { Videos } from '../components'
import {fetchFromApi} from '../utils/fetchFromApi'
import { useParams } from 'react-router-dom'

if(window.location.href.includes('kalbe')) {
  document.title = "Gözden Kalbe"
} else {
  document.title = "İzle Eğlen"
}

const SearchFeed = () => {
  const [pageToken, setPageToken] = useState('')
  const [videos, setVideos] = useState([])
  const { searchTerm } = useParams()

  useEffect(() => {
    if(window.location.href.includes('kalbe')) {
      document.title = "Gözden Kalbe"
    } else {
      document.title = "İzle Eğlen"
    }
    
    fetchFromApi(`search?part=snippet&q=${searchTerm}&pageToken=${pageToken}`)
    .then((data)=> {
      setPageToken(data.nextPageToken)
      setVideos(data.items)
    })
  }, [searchTerm])
  
  return (
  <Box p={2} component='div' sx={{overflow: 'scroll', height:'90vh', flex: 2 }}>
    <Typography variant='h4' fontWeight='bold' mb={2} 
      sx={{color:'white'}}>
      {searchTerm}
      <span style={{ color: 'red'}}></span>
    </Typography>
    <Videos videos={videos}/>
    {pageToken && 
      <Stack direction='row'>
        <Button>Previous</Button>
        <Button>Next</Button>
      </Stack>
    }
  </Box>
  )
}

export default SearchFeed
